





















































import { Component, Vue } from 'vue-property-decorator';

@Component
export default class LoginOptions extends Vue {
  goTo(route: string) {
    this.$router.push({ name: `login-form`, params: { type: route } });
  }
}
